<template>
  <div class="body-main">
    <div class="main">
      <bread-crumb :list="breadCrumbList"></bread-crumb>
      <div class="wrap">
        <div class="wrap-left">
          <router-link class="wrap-left-item" :to="{path: '/about-info/index', query:{type: 1}}">关于我们</router-link>
          <router-link class="wrap-left-item" :to="{path: '/about-info/index', query:{type: 2}}">企业理念</router-link>
          <router-link class="wrap-left-item" :to="{path: '/about-info/index', query:{type: 3}}">隐私条款</router-link>
          <router-link class="wrap-left-item" :to="{path: '/about-info/index', query:{type: 4}}">付款方式</router-link>
        </div>
        <div class="wrap-right">
          <p class="ql-editor" v-html="aboutUs"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { searchByType } from "@/api/index";
  import BreadCrumb from '@/components/BreadCrumb';
  export default {
    name: "AboutInfo",
    components: {
      BreadCrumb
    },
    inject: ['reload'],
    data() {
      return {
        breadCrumbList: [],
        aboutUs: ''
      };
    },
    created() {
      this.breadCrumbFunc();
    },
    watch: {
      $route() {
        this.reload();
      }
    },
    methods: {
      breadCrumbFunc() {
        let name='';
        if (this.$route.query.type == 1)  {
          name = '关于我们';
          this.getAboutUsData();
        } else if (this.$route.query.type == 2)  {
          name = '企业理念';
          this.getCorporatePhilosophyData()
        } else if (this.$route.query.type == 3)  {
          name = '隐私条款';
          this.getContactUsData()
        } else if (this.$route.query.type == 4)  {
          name = '付款方式';
          this.getPaymentMethodData()
        }
        this.breadCrumbList = [
          {
            name: name
          },
        ];
      },
      // 关于我们
      getAboutUsData() {
        searchByType({type_bn: 's_page_type_101'}).then(res => {
          this.aboutUs = res.data[0].page_val;
        }).catch(err => {
          console.log(err);
        });
      },
      // 企业理念
      getCorporatePhilosophyData() {
        searchByType({type_bn: 's_page_type_102'}).then(res => {
          this.aboutUs = res.data[0].page_val;
        }).catch(err => {
          console.log(err);
        });
      },
      // 联系我们
      getContactUsData() {
        searchByType({type_bn: 's_page_type_103'}).then(res => {
          this.aboutUs = res.data[0].page_val;
        }).catch(err => {
          console.log(err);
        });
      },
      // 付款方式
      getPaymentMethodData() {
        searchByType({type_bn: 's_page_type_104'}).then(res => {
          this.aboutUs = res.data[0].page_val;
        }).catch(err => {
          console.log(err);
        });
      },
    }
  };
</script>

<style lang="scss" scoped>
.body-main {
  width: 100%;
  background: rgba(245, 245, 245, 1);
  .main {
    overflow: hidden;
    width: 1200px;
    margin: 0 auto;
    .wrap {
      display: flex;
      margin-bottom: 20px;
      &-left {
        width:297px;
        height:284px;
        background:rgba(255,255,255,1);
        margin-right: 25px;
        display: flex;
        flex-direction: column;

        &-item {
          text-align: center;
          width: 100%;
          height: 68px;
          line-height: 68px;
          font-size:18px;
          font-family:Microsoft YaHei;
          font-weight:400;
          color:#333333;
          margin-bottom: 4px;
          &.router-link-exact-active {
            color:rgba(255,255,255,1);
            width: 310px;
            padding-right: 13px;
            background: url('../../assets/index/icon-028.png') center/contain no-repeat;
          }
        }
      }
      &-right {
        width: 877px;
        /* height:899px; */ 
        min-height:899px; height:auto!important; height:899px;
        background:rgba(255,255,255,1);
      }
    }
  }
}

</style>
